import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import axiosRetry from 'axios-retry';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

axiosRetry(service, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

// request interceptor
service.interceptors.request.use(
    config => {
      // do something before request is sent

      config.headers['authtoken'] = process.env.VUE_APP_TOKEN
      
      return config
    },

  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response

    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200 && res.status !== 201) {
      console.log('Request error')
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
